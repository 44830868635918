<template>
    <div>
        1132
    </div>
</template>

<script>
export default {
        
}
</script>

<style lang="scss">
@import "~@/assets/css/ai_word.scss";
@media (min-width: 1200px) {
  .container,
  .header {
    width: 1170px;
  }
}
</style>